.variantTable {
    width: 100%;
    max-width: 700px;

    th,
    td {
        text-align: center;
    }
    th:first-of-type,
    td:first-of-type {
        text-align: left;
        width: 100%;
    }
    tbody tr:hover {
        background-color: rgba(173, 216, 230, 0.2);
    }
}

@media (max-width: 600px) {
    th.labels {
        display: none;
    }
    td.labels {
        display: none;
    }
}

th.labels {
    text-align: right;
}

td.labels {
    text-align: right;
    vertical-align: top;
}

th.actions {
    text-align: right;
}

td.actions {
    height: 100%;
    text-align: right;
    vertical-align: top;
}

.actionsContainer {
    display: flex;
    align-items: center;
}

.modal {
    max-width: 90%;
    width: 600px;
    position: absolute !important;
}

@media (max-width: 600px) {
    .modal {
        top: 0 !important;
    }
}

.tooltip {
    i {
        font-size: 18px;
    }
}

.inputWeight {
    text-align: right;
}

.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex {
    display: flex;
    align-items: center;
}

.marginL10 {
    margin-left: 10px;
}

.addVariantButton {
    margin: 1rem 0;
}

.paragraph {
    max-width: 400px;
}

.helperText {
    display: block;
    margin-top: 0.5rem;
}

.container {
    width: 350px;
}

.container > * {
    margin: 0.6rem 0;
}

.emailField {
    width: 100%;
}

.form > * {
    margin: 0.6rem 0;
}

.logo {
    margin: 0 auto;
    display: block;
    height: 100px;
    width: 100px;
}

.button {
    min-width: 150px;
    margin: 1rem auto;
    display: block;
}

@media (max-width: 500px) {
    .container {
        width: 100%;
    }
}

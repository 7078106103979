.card {
    width: 100%;
    padding: var(--card-padding);
    margin: var(--card-margin-y) 0;
    border-radius: 10px;
    box-shadow: none;
}

.header {
    font-size: var(--h1-size);
    font-weight: 'bold';
    margin: 0 0 0.5rem 0;
}

.reportCardContainer {
    display: flex;
    justify-content: space-between;
}
.reportCardHealth {
    padding: 10px;
}
.reportCardAction {
    padding: 10px;
}
.reportCardToggle {
    padding: 10px;
}

.reportCardHealthInnerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
    height: 80%;
}

.reportCardHealthRating {
    font-size: 2rem;
    font-weight: bold;
    color: var(--success);
}

.reportCardList {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.reportCardList li {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
}

.reportCardList li span {
    margin: 0;
    padding: 0;
    margin-left: 0.5rem;
    font-size: var(--p-size);
}

.check,
.danger {
    margin-right: 5px;
}

.check {
    color: var(--success);
}

.danger {
    color: var(--danger);
}

.reportCardActionContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.reportCardActionText {
    max-width: 300px;
    font-size: var(--p-size);
    margin-left: 35px;
}
.reportCardNoActionText {
    max-width: 300px;
    font-size: var(--p-size);
    margin-left: 15px;
}

.reportCardBtn {
    background-color: #f2f2f2;
}

.healthDanger {
    color: var(--danger);
}

.healthWarning {
    color: var(--warning);
}
.lastUpdate {
    color: #585858;
}

@media (max-width: 600px) {
    .reportCardContainer {
        flex-wrap: wrap;
    }
    .reportCardToggle {
        margin: 10px 5px;
        flex-basis: 100%;
    }
}
